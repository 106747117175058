.outerWrapperInput {
	--widthDefault: 100%;
	--widthTablet: 100%;
	--widthDesktop: 100%;
	--widthBigScreen: 100%;

	--mt: var(--spc16);

	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: var(--mt);
	width: var(--widthDefault);

	@media (--tablet) {
		width: var(--widthTablet);
	}

	@media (--desktop) {
		width: var(--widthDesktop);
	}

	@media (--big-screen) {
		width: var(--widthBigScreen);
	}
}

.innerWrapperInput {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	background-color: #ffffff;
	border-radius: 3px;
}
